@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'aAtmospheric';
    src: url('assets/fonts/aAtmospheric.ttf') format('truetype');
}

@font-face {
    font-family: 'Alata';
    src: url('assets/fonts/Alata-Regular.ttf') format('truetype');
}

@layer components {
    .btn {
        @apply w-60 h-full bg-gradient-to-br from-yellow-one to-orange-one text-gray-one font-bold text-3xl rounded-lg border-none;
    }
    .btn-salon {
       @apply w-5/6  bg-gradient-to-br from-yellow-one to-orange-one rounded-lg text-2xl font-alata tracking-wider font-medium py-2;
    }
    .btn-return {
        @apply bg-[radial-gradient(ellipse_at_top_left,_var(--tw-gradient-stops))] from-amber-300 via-yellow-500 to-yellow-300 rounded-lg h-10 w-10 flex justify-center items-center;
    }
    .btn-gray {
        @apply flex justify-center items-center w-40 h-10 bg-gray-600 font-bold text-2xl rounded-lg px-6 text-white pt-2;
    }
    .btn-secondary {
        @apply w-36 h-10 bg-gradient-to-br from-orange-one via-yellow-one to-orange-one text-gray-one font-bold text-3xl rounded-lg;
    }
    .btn-profil {
        @apply bg-gradient-to-br from-orange-one via-yellow-one to-orange-one text-gray-one py-1 px-3 rounded-lg mt-3;
    }
    .btn-connexion {
        @apply bg-gradient-to-br from-orange-one to-yellow-one text-gray-one py-1 px-3 rounded-lg mt-3 h-12 w-[300px] text-2xl lg:text-xl font-bold;
    }
    .btn-footer {
        @apply bg-gradient-to-br from-orange-one to-yellow-one text-gray-one h-12 w-12 rounded-md flex justify-center items-center;
    }
    .label {
        @apply block text-gray-300 text-sm;
    }
    .inputLogin {
        @apply text-xs w-full appearance-none bg-stone-200 leading-tight focus:outline-none text-black border-b border-white;
    }
    .inputInvalid {
        @apply text-xs w-full appearance-none bg-stone-200 leading-tight focus:outline-none text-white border-b border-red-600 py-1;
    }
    .title {
        @apply text-2xl font-bold text-white pt-2;
    }
    .profil-title {
        @apply text-gray-300;
    }
}

button {
    cursor: pointer;
}

.scrollBar::-webkit-scrollbar {
    width: 0.5rem;
    margin-right: 10px;
}

.scrollBar::-webkit-scrollbar-track {
    background: rgb(159, 95, 56);
}

.scrollBar::-webkit-scrollbar-thumb {
    background: rgb(255, 255, 255);
}

.border-link-go{
    box-shadow: 0 0 4px 8px rgba(62,192,0,0.69);
}

/*label    block text-gray-700 text-sm font-bold mb-2*/
/*inputText   text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline*/
